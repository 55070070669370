const initialState = {
  requestingFetchIncomings: false,
  successfulFetchIncomings: false,
  errorsFetchIncomings: false,
  incomings: null,
  requestingCreateManifest: false,
  successfulCreateManifest: false,
  errorsCreateManifest: false,
  createManifestResult: null,
  requestingFetchWMSOrderForReturn: false,
  successfulFetchWMSOrderForReturn: false,
  errorsFetchWMSOrderForReturn: false,
  wmsOrderForReturn: null,
};

const wmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_WMS_INCOMINGS_REQUESTING":
      return {
        ...state,
        requestingFetchIncomings: true,
        successfulFetchIncomings: false,
        errorsFetchIncomings: false,
      };
    case "FETCH_WMS_INCOMINGS_SUCCESS":
      return {
        ...state,
        requestingFetchIncomings: false,
        successfulFetchIncomings: true,
        errorsFetchIncomings: false,
        incomings: action.value,
      };
    case "FETCH_WMS_INCOMINGS_ERROR":
      return {
        ...state,
        requestingFetchIncomings: false,
        successfulFetchIncomings: false,
        errorsFetchIncomings: true,
      };
    case "CREATE_MANIFEST_REQUESTING":
      return {
        ...state,
        requestingCreateManifest: true,
        successfulCreateManifest: false,
        errorsCreateManifest: false,
      };
    case "CREATE_MANIFEST_SUCCESS":
      return {
        ...state,
        requestingCreateManifest: false,
        successfulCreateManifest: true,
        errorsCreateManifest: false,
        createManifestResult: action.value,
      };
    case "CREATE_MANIFEST_ERROR":
      return {
        ...state,
        requestingCreateManifest: false,
        successfulCreateManifest: false,
        errorsCreateManifest: true,
        createManifestResult: action.value,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_REQUESTING":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: true,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: false,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_SUCCESS":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: false,
        successfulFetchWMSOrderForReturn: true,
        errorsFetchWMSOrderForReturn: false,
        wmsOrderForReturn: action.value,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_ERROR":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: false,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: true,
        wmsOrderForReturn: null,
      }
    case "RESET_WMS_ORDER_FOR_RETURN":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: false,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: false,
        wmsOrderForReturn: null,
      }
    case "RESET_MANIFEST_FORM":
      return initialState;
    default:
      return state;
  }
};

export default wmsReducer;
